import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAH7eXARukgBCLFOfw4-Kl-NatHc-fOA68",
  authDomain: "fourier-6e14d.firebaseapp.com",
  databaseURL: "https://fourier-6e14d.firebaseio.com",
  projectId: "fourier-6e14d",
  storageBucket: "fourier-6e14d.appspot.com",
  messagingSenderId: "1032228035742",
  appId: "1:1032228035742:web:a23f9192ddbeed2fa4af8f"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;