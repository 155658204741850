import { createMuiTheme } from '@material-ui/core/styles';
export default createMuiTheme({
    palette: {
      primary: {
        main: '#049bbb'
      },
      secondary: {
        main: '#63bb44'
      }
    },
    overrides: {
      MuiButton: {
        containedSecondary: {
          color: '#ffffff',
        },
      }
    }
});