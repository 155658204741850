import React, { useState } from 'react';
import firebase from 'firebase/app';
import moment from 'moment-timezone';
import { Container, IconButton, Typography, Checkbox, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import Services from '../../services';
import Table  from '../../components/Table';
import DeleteDialog from '../../components/DeleteDialog';
import Alert from '../../components/Alert';
import IncidentRegistersDialog from './IncidentRegistersDialogView';

const header = [
  {
    prop: 'folio',
    name: 'Folio'
  },
  {
    prop: 'person',
    name: 'Persona'
  },
  {
    prop: 'status',
    name: 'Estatus'
  },
  {
    prop: 'url',
    name: 'Url'
  }
];

const IncidentRegistersView = ({location}) => {
  useFirestoreConnect([
    {
      collection: "incidents", 
      doc: location.state.incident.id
    },
    {
      collection: "registers", 
      orderBy: ['registry', 'desc'], 
      where: ['incidenId', '==', location.state.incident.id]
    }
  ]);
  const incident = useSelector(
    ({ firestore: { data } }) => data.incidents && data.incidents[location.state.incident.id]
  );
  const registers = useSelector(state => state.firestore.ordered.registers);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [error , setError] = useState("");
  const [register, setRegister] = useState(null);
  const [idDelete, setIdDelete] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [imagesDeleteStorage, setImagesDeleteStorage] = useState([]);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  if(!isLoaded(registers) || !isLoaded(incident)) return null;

  const add = async () => {
    try {
      setSaving(true);

      let images = [];

      if(register.images.length) {
        const dateNow = Date.now().toString(); 

        for (let i = 0; i < register.images.length; i++) {
          const image = register.images[i]
          const url = await Services.uploadFile("registers/" + dateNow + image.imagePath.name, image.imagePath);
          
          if(url !== "error") {
            images.push({
              imageUrl: url,
              imagePath: "registers/" + dateNow + image.imagePath.name
            })
          }
        }
      }
      
      const ref = await Services.addDocument("registers", {
        incidenId: location.state.incident.id,
        folio: register.folio,
        person: register.person,
        status: register.status,
        url: register.url,
        sent: register.sent,
        registry: firebase.firestore.Timestamp.fromDate(new Date()),
        images: images
      });

      await Services.updateDocument("incidents", location.state.incident.id, {
        registerIds: [...incident.registerIds, ref.id]
      });
    } catch (error) {
      console.log(error); 
      setError("Error al guardar registro, intentelo de nuevo");
    } finally{
      setOpen(false);
      setSaving(false);
    }
  }

  const update = async () => {
    try {
      setSaving(true);
      let images = [...register.images];
      
      if(register.images.length) {
        const dateNow = Date.now().toString(); 

        for (let i = 0; i < register.images.length; i++) {
          const image = register.images[i];

          if(image.imagePath instanceof File) {
            const url = await Services.uploadFile("registers/" + dateNow + image.imagePath.name, image.imagePath);

            if(url !== "error") {
              images[i] = {
                imageUrl: url,
                imagePath: "registers/" + dateNow + image.imagePath.name
              };
            }
            else {
              images.splice(i, 1);
            }
          }
        }
      }

      await Services.updateDocument("registers", register.id, {
        folio: register.folio,
        person: register.person,
        status: register.status,
        url: register.url,
        sent: register.sent,
        images: images
      });

      if(imagesDeleteStorage.length) {
        for (let i = 0; i < imagesDeleteStorage.length; i++) {
          try {
            await Services.deleteFile(imagesDeleteStorage[i]);
          } catch (error) {
            console.log(error);
            continue;
          }
        }
      }
    } catch (error) {
      console.log(error); 
      setError("Error al editar registro, intentelo de nuevo");
    } finally{
      setOpen(false);
      setSaving(false);
    }
  }

  const updateSent = async (value, id) => {
    try {
      await Services.updateDocument("registers", id, {
        sent: value
      });
    } catch (error) {
      console.log(error); 
      setError("Error al editar registro, intentelo de nuevo");
    } finally{
      setOpen(false);
    }
  }

  const del = async () => {
    try {
      setDeleting(true);

      await Services.delete("registers", idDelete);
      await Services.updateDocument("incidents", location.state.incident.id, {
        registerIds: incident.registerIds.filter(rId => rId !== idDelete)
      });

      const _register = registers.find(r => r.id === idDelete);

      if(_register.images.length) {
        for (let i = 0; i < _register.images.length; i++) {
          try {
            await Services.deleteFile(_register.images[i].imagePath);
          } catch (error) {
            console.log(error);
            continue;
          }
        }
      }
    } catch (error) {
      console.log(error); 
      setError("Error al eliminar registro, intentelo de nuevo");
    } finally{
      setOpenDelete(false);
      setDeleting(false);
    }
  }

  return (
    <Container style={{marginTop: 30}}>
      <div style={{ width: '100%' }}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h5">{incident.name}</Typography>
          </Box>
          <Box>
            <IconButton 
              onClick={() => {
                setOpen(true);
                setMode("add"); 
                setRegister({
                  folio: "",
                  person: "",
                  status: "",
                  url: "",
                  sent: false,
                  images : [],
                  description: ""
                });
              }} 
              style={{backgroundColor: "#63bb44", color: "white", float: "right"}}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      </div>
      {
        registers.length
        ?
          <Table 
            data={registers}
            filter={["folio", "person", "status", "url"]}
            header={header}
            paginated
            extraRows={[
              {
                prop: 'registry',
                name: 'Fecha de registro',
                cell: row => (
                  <>{moment(Date.parse(row.registry.toDate())).format('DD-MM-YYYY HH:mm')}</> 
                )
              }, 
              {
                prop: 'del',
                name: 'Eliminar',
                cell: row => (
                  <IconButton onClick={() => {
                    setOpenDelete(true);
                    setIdDelete(row.id);
                  }}>
                    <DeleteIcon/>
                  </IconButton>
                )
              }, 
              {
                prop: 'edit',
                name: 'Editar',
                cell: row => (
                  <IconButton onClick={() => {
                    setOpen(true);
                    setMode("edit");
                    setRegister(row);
                  }}>
                    <EditIcon/>
                  </IconButton>
                )
              },
              {
                prop: 'sent',
                name: 'Enviado',
                cell: row => (
                  <Checkbox 
                    checked={row.sent} 
                    onChange={async () => await updateSent(!row.sent, row.id)} 
                  />
                )
              }
            ]}
          />
        :
          <Typography align="center" style={{paddingTop: "30vh"}} variant="h4">Sin registros.</Typography>
      }
      <IncidentRegistersDialog 
        open={open}
        onClose={() => setOpen(false)}
        onAccept={mode === "add" ? add : update}
        mode={mode}
        register={register}
        onChange={(prop, value) => {
          if(prop !== "images") {
            setRegister({...register, [prop]: value});
          }
          else {
            let images = [...register.images];
            
            for (let index = 0; index < value.length; index++) {
              images.push({
                imageUrl: URL.createObjectURL(value[index]),
                imagePath: value[index],
              })
            }

            setRegister({...register, [prop]: images});
          }
        }}
        deleteImage={(index, image) => {
          if(typeof image.imagePath === 'string') {
            setImagesDeleteStorage([...imagesDeleteStorage, image.imagePath]);
          }
        
          setRegister({...register, images: register.images.filter((r, i) => i !== index)})
        }}
        saving={saving}
      />
      <DeleteDialog 
        open={openDelete}
        onCancel={() => setOpenDelete(false)}
        onClose={() => setOpenDelete(false)}
        onAccept={del}
        loading={deleting}
      />
      <Alert 
        open={Boolean(error)}
        onClose={() => setError("")}
        message={error}
        severity="error"
      />
    </Container>
  )
}

export default IncidentRegistersView;
