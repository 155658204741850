import React, { useState } from 'react';
import firebase from 'firebase/app';
import moment from 'moment-timezone';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Container, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EditIcon from '@material-ui/icons/Edit';
import HomeDialog from './HomeDialogView';
import Alert from '../../components/Alert';
import Services from '../../services';
import Loader from '../../components/Loader';
import Table from '../../components/Table';
import DeleteDialog from '../../components/DeleteDialog';

const header = [
  {
    prop: 'name',
    name: 'Nombre'
  },
];

const HomeView = ({history}) => {
  useFirestoreConnect([
    {collection: "incidents", orderBy: ['registry', 'desc']}
  ]);
  const incidents = useSelector(state => state.firestore.ordered.incidents);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [error , setError] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [incident, setIncident] = useState(null);

  if (!isLoaded(incidents)) {
    return <Loader/>;
  }

  const add = async () => {
    try {
      await Services.addDocument("incidents", {
        name: incident.nombre,
        registry: firebase.firestore.Timestamp.fromDate(new Date()),
        registerIds: []
      });
    } catch (error) {
      console.log(error); 
      setError("Error al guardar la incidencia, intentelo de nuevo.");
    } finally{
      setOpen(false);
    }
  }

  const update = async () => {
    try {
      let _incident = {...incident};
      const id = _incident.id;
      
      delete _incident.id; 

      await Services.updateDocument("incidents", id, _incident);
    } catch (error) {
      console.log(error); 
      setError("Error al guardar incidencia, intentelo de nuevo");
    } finally{
      setOpen(false);
    }
  }

  const del = async () => {
    try {
      const _incident = incidents.find(i => i.id === deleteId);
      
      if(_incident.registerIds.length) {
        setError("Error al borrar incidencia, esta incidencia tiene registros de incidencias.");
        return;
      }

      await Services.delete("incidents", deleteId);
    } catch (error) {
      console.log(error);
      setError("Error al borrar el registro, intentelo de nuevo.");
    } finally {
      setDeleteId("");
    }
  }

  return (
    <Container style={{marginTop: 30}}>
      <IconButton 
        onClick={() => {
          setOpen(true); 
          setMode("add"); 
          setIncident({nombre: ""});
        }} 
        style={{backgroundColor: "#63bb44", color: "white", float: "right"}}
      >
        <AddIcon />
      </IconButton>
      <Table 
        data={incidents}
        filter={["name"]}
        header={header}
        paginated
        extraRows={[
          {
            prop: 'registry',
            name: 'Fecha de registro',
            cell: row => (
              <>{moment(Date.parse(row.registry.toDate())).format('DD-MM-YYYY HH:mm')}</> 
            )
          }, 
          {
            prop: 'del',
            name: 'Eliminar',
            cell: row => (
              <IconButton onClick={() => setDeleteId(row.id)}>
                <DeleteIcon/>
              </IconButton>
            )
          }, 
          {
            prop: 'edit',
            name: 'Editar',
            cell: row => (
              <IconButton onClick={() => {
                setOpen(true);
                setMode("edit");
                setIncident(row);
              }}>
                <EditIcon/>
              </IconButton>
            )
          },
          {
            prop: 'addRegister',
            name: 'Agregar registros de incidencia',
            cell: row => (
              <IconButton onClick={() => history.push({
                pathname: '/registros-incidencias',
                state: { incident: row }
              })}>
                <ArrowForwardIosIcon/>
              </IconButton>
            )
          }
        ]}
      />
      <HomeDialog 
        open={open}
        onClose={() => setOpen(false)}
        onAccept={mode === "add" ? add : update}
        mode={mode}
        data={incident}
        onChange={(prop, value) => setIncident({...incident, [prop]: value})}
      />
      <Alert 
        open={Boolean(error)}
        onClose={() => setError("")}
        message={error}
        severity="error"
      />
      <DeleteDialog 
        open={Boolean(deleteId)}
        onCancel={() => setDeleteId("")}
        onClose={() => setDeleteId("")}
        onAccept={del}
      />
    </Container>
  )
}

export default HomeView;  
