import React, { useState } from 'react';
import { 
  TextField, Box, Typography, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, FormHelperText,
  Button, Grid, MobileStepper, IconButton, CircularProgress, FormControl, MenuItem, Select, InputLabel
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteIcon from '@material-ui/icons/Delete';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  img: {
    height: 255,
    display: 'block',
    overflow: 'hidden',
    margin: "auto",
    objectFit: "cover",
    width: "400px",
  },
}));


const IncidentRegistersDialogView = ({open, onClose, onAccept, mode, register = {}, onChange, saving, deleteImage}) => {
  const [errors, setErrors] = useState([]);
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = register ? register.images.length : 0;

  if(!register) return null;

  const handleAccept = () => {
    if(saving) return;

    let _errors = [];
    const notValidateProps = ["images", "id", "sent", "index", "url", "description", "person"]; 

    for (const key in register) {
      if(!register[key] && !notValidateProps.includes(key)) {
        _errors.push(key);
      }
    }

    setErrors(_errors);

    if(_errors.length) return;

    onAccept();
  }

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const handleStepChange = (step) => setActiveStep(step);

  return (
    <Dialog
      className={classes.dialog}
      maxWidth="xl"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{mode === "add" ? "Agregar registro de incidencia" : "Editar registro de incidencia"}</DialogTitle>
      <DialogContent className={classes.dialog}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12} sm={12}>
            <TextField 
              error={errors.includes("folio")}
              fullWidth
              type="text"
              label="Folio"
              variant="outlined"
              value={register.folio}
              onChange={(event) => onChange("folio", event.target.value)}
              helperText={'Favor de llenar el campo "Folio".'}
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <FormControl variant="outlined" error={errors.includes("status")} fullWidth>
              <InputLabel >Estatus</InputLabel>
              <Select
                value={register.status}
                onChange={(event) => onChange("status", event.target.value)}
                label="Estatus"
              > 
                <MenuItem value={"NUEVO"}>NUEVO</MenuItem>
                <MenuItem value={"CORREGIDO"}>CORREGIDO</MenuItem>
                <MenuItem value={"CANCELADO"}>CANCELADO</MenuItem>
              </Select>
              <FormHelperText>Favor de llenar el campo "Estatus".</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <TextField 
              fullWidth
              type="text"
              label="Persona"
              variant="outlined"
              value={register.person}
              onChange={(event) => onChange("person", event.target.value)}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextField 
              rows={3}
              multiline
              style={{marginTop: 10}}
              fullWidth
              type="text"
              label="Descripción del problema"
              variant="outlined"
              value={register.description}
              onChange={(event) => onChange("description", event.target.value)}
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <TextField 
              style={{marginTop: 10}}
              fullWidth
              type="text"
              label="Url"
              variant="outlined"
              value={register.url}
              onChange={(event) => onChange("url", event.target.value)}
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12} style={{textAlign: "center"}}>
            <Button
              style={{marginTop: 10}}
              variant="contained"
              component="label"
              color="secondary"
            >
              Subir imagenes
              <input
                multiple
                type="file"
                hidden
                onChange={(event) => {
                  if(event.target.files && event.target.files.length) {
                    onChange("images", event.target.files)
                  }
                }}
                accept="image/*" 
              />
            </Button>           
          </Grid>
          <Grid item md={4} xs={12} sm={12} style={{textAlign: "center"}}>
            <div style={{ marginTop: 10, paddingLeft: 15 }}>
              <Typography style={{ marginTop: 10 }} align="center">
                Enviado
              </Typography>
              <Checkbox
                onChange={(event) => onChange("sent", event.target.checked)}
                color="primary"
                checked={register.sent}
              />
            </div>
          </Grid>
         
        </Grid>
        {
          register.images.length 
          ?
            <Box display="flex" justifyContent="center">
              <Box>
                <div className={classes.root}>
                  <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    interval={10000}
                  >
                  {register.images.map((image, index) => (
                    <div key={index}>
                      <IconButton 
                        style={{backgroundColor: "#049bbb", marginLeft: 52, marginBottom: -30,  color: "white"}}
                        onClick={() => {
                          if(activeStep !== 0) {
                            handleBack();
                          }
                          deleteImage(index, image);
                        }}
                      >
                        <DeleteIcon/>
                      </IconButton>
                      <div>
                        {Math.abs(activeStep - index) <= 2 ? (
                          <img className={classes.img} src={image.imageUrl} />
                        ) : null}
                      </div>
                    </div>
                  ))}
                  </AutoPlaySwipeableViews>
                  <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                      <Button size="small" endIcon={<ArrowForwardIosIcon/>} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        Siguiente
                      </Button>
                    }
                    backButton={
                      <Button size="small" startIcon={<ArrowBackIosIcon/>} onClick={handleBack} disabled={activeStep === 0}>
                        Atras
                      </Button>
                    }
                  />
                </div>
              </Box>
            </Box>
          : 
            <Typography align="center" style={{marginTop: 20}}>Sin imagenes...</Typography>
        }
      </DialogContent>
      <DialogActions style={{marginTop: 15}}>
        <Button 
          variant="contained"
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button 
          onClick={handleAccept} 
          variant="contained"
          color="secondary"
        >
        {
          saving 
          ?
            <CircularProgress size={25} style={{color: "white"}}/> 
          :
            "Guardar"
        }
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default IncidentRegistersDialogView;
