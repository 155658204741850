import React from 'react';
import { 
  Button, Dialog, DialogActions, DialogContent, DialogContentText, 
  DialogTitle, CircularProgress
} from '@material-ui/core';

const DeleteDialog = ({ open, onClose, onCancel, onAccept, loading }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
   >
      <DialogTitle >¡Advertencia!</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          ¿Seguro que desea eliminar este registro?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancelar
        </Button>
        { 
          loading
          ?
            <CircularProgress />
          :
            <Button onClick={onAccept} color="primary" autoFocus>
              Aceptar
            </Button>
        }
      </DialogActions>
  </Dialog>
  );
};

export default DeleteDialog;