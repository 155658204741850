import React from "react";
import './styles.css';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import theme from './components/Theme';
import { ThemeProvider } from '@material-ui/core/styles';
import Header from './components/Header';
import Footer from './components/Footer';
import RouteAuth from './components/RouteAuth';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { HomeView, LogInView, NoticePrivacy, IncidentRegistersView, ScheduleView } from './views';

const containerStyle = {
  backgroundColor: '#fdfdfd',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center',
  minHeight: '100vh',
};

const bodyStyles = {
  margin: 0,
  padding: 0
};

export default () => {
  const auth = useSelector(state => state.firebase.auth);
  
  if (!isLoaded(auth)){
    return null;
  }

  return (
    <div style={ containerStyle }>
      <ThemeProvider theme={theme}>
        <Router>
          { isEmpty(auth) && <Redirect to="/login" /> }
          { !isEmpty(auth) && <Header hideHeaderLinks /> }
          <div style={isLoaded(auth) && !isEmpty(auth) ? bodyStyles : null }>
            <Switch>
              <RouteAuth exact path="/login" component={LogInView} />
              <RouteAuth exact path="/" component={ScheduleView} requireAuth />
              <RouteAuth exact path="/noticePrivacy" component={NoticePrivacy} /> 
              <RouteAuth exact path="/incidencias" component={HomeView} /> 
              <RouteAuth exact path="/registros-incidencias" component={IncidentRegistersView} /> 
            </Switch>
          </div>
          { !isEmpty(auth) && <Footer/> }
        </Router>
      </ThemeProvider>
    </div>
  )
}
