import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../components/Form';

const HomeDialogView = ({open, onClose, onAccept, mode, data, onChange}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{mode === "add" ? "Agregar incidencia" : "Editar incidencia"}</DialogTitle>
      <DialogContent>
        <Form
          data={data}
          mode={mode}
          onChange={onChange}
          showButtons
          onCancel={onClose}
          onAccept={onAccept}
        />
      </DialogContent>
    </Dialog>
  );
}

export default HomeDialogView
