import React, { useState } from 'react';
import Loader from './../../components/Loader';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from './../../components/Alert';
import logo from './../../white-logo.png';
import LoginForm from './LoginForm';
import Footer from './Footer'; 
import firebase from './../../firebase';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirestore } from 'react-redux-firebase';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    maxWidth: '950px',
    margin: '0 auto',
    paddingLeft: '100px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
    }
  },
  root: {
    backgroundColor: '#fafafa'
  },
  logo: {
    width: '340px',
    marginTop: '-70px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0'
    }
  }
}));

const LoginView = () => {
  const classes = useStyles(); 
  const auth = useSelector(state => state.firebase.auth);
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const firestore = useFirestore();

  const login = async (email, password)  =>  {
    setLoading(true);
    firebase
      .login({ email, password })
      .catch(error => {
        setLoading(false);
        handleError(error);
      });
  }

  const handleError = error => {
    switch (error.code) {
      case 'auth/wrong-password':
      case 'auth/too-many-requests':
        setError(error.message);
        break;
      default:
        setError('Ocurrió un error en el servidor, por favor intentalo más tarde');
    }
  }

  if (!isLoaded(auth)) {
    return <Loader />;
  }
 
  if (!isEmpty(auth)) {
    return <Redirect to="/"/>
  } 

  return (
    <div className={classes.root}>
      <Grid
        className={classes.container}
        container
        alignItems="stretch">
        <Grid
          item
          xs={12}
          sm={6}
          container
          alignItems="center"
          justify="center">
            <img className={classes.logo} src={logo}/>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          direction="column"
          alignItems="center"
          justify="center">
            <LoginForm
              loading={loading}
              onSubmit={(email, password) => login(email, password)}/>
            <Footer />
        </Grid>
      </Grid>
      <Alert
        open={Boolean(error)}
        onClose={() => setError(null)}
        message={error}
        severity="error" />
    </div>
  );
};

export default LoginView;