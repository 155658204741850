import React, { useState } from "react";
import { DialogActions, Button, TextField, Checkbox, CircularProgress,
  Box, Typography, Select, MenuItem, FormControl, FormHelperText, Grid } from "@material-ui/core";

const capitalize = word => {
  let _word;
  _word = word[0].toUpperCase() + word.slice(1);
  _word = _word.split(/(?=[A-Z])/);

  if (Array.isArray(_word)) {
    _word.forEach((w, index) => {
      if (index === 0) {
        _word = w;
      } else {
        _word = _word + ` ${w}`;
      }
    });
  }

  return _word;
};

const Form = ({inColumns, data, onCancel, onAccept, onChange, showButtons, saving, mode}) => {
  const [inputErrors, setInputErrors] = useState([]);

  const getInput = (property) => {
    if (typeof data[property] === "string" && !property.includes("Id") && property !== "id") {
      return (
        <TextField
          error={inputErrors.includes(property)}
          fullWidth
          type="text"
          label={capitalize(property)}
          variant="outlined"
          value={data[property]}
          onChange={(event) => onChange(property, event.target.value)}
          helperText={
            'Favor de llenar el campo "' + capitalize(property) + '".'
          }
        />
      );
    }
    if (typeof data[property] === "number" && !property.includes("Id") && property !== "id" && property !== "index") {
      return (
        <TextField
          error={inputErrors.includes(property)}
          style={{ marginTop: 10 }}
          fullWidth
          type="number"
          label={capitalize(property)}
          variant="outlined"
          value={data[property]}
          onChange={(event) => onChange(property, event.target.value)}
          helperText={
            'Favor de llenar el campo "' + capitalize(property) + '".'
          }
        />
      );
    }
    if (typeof data[property] === "boolean") {
      return (
        <div style={{ marginTop: 10, paddingLeft: 15 }}>
          <Box display="flex" justifyContent="rigth">
            <Typography style={{ marginTop: 10 }} align="center">
              {capitalize(property)}
            </Typography>
            <Checkbox
              onChange={(event) => onChange(property, event.target.checked)}
              color="primary"
              checked={data[property]}
            />
          </Box>
        </div>
      );
    }
    if (Array.isArray(data[property]) && !property.toUpperCase().includes("ID")) {
      return (
        <FormControl
          error={
            property[property.length - 2] === "e" 
              ? inputErrors.includes(property.substring(0, property.length - 2) + "Id")
              : inputErrors.includes(property.substring(0, property.length - 1) + "Id")
          }
          fullWidth
          variant="outlined"
        >
          <Select
            value={property[property.length - 2] === "e" 
              ? (data[property.substring(0, property.length - 2) + "Id"]) 
              : (data[property.substring(0, property.length - 1) + "Id"])}
            onChange={(event) =>
              onChange(
                property[property.length - 2] === "e" 
                  ? property.substring(0, property.length - 2) + "Id"
                  : property.substring(0, property.length - 1) + "Id",
                event.target.value
              )
            }
          >
            {data[property].map((cliente, index) => (
              <MenuItem key={index} value={cliente.id}>
                {cliente.nombre}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {'Favor de seleccionar un/una "' + (
              property[property.length - 2] === "e" 
                ? capitalize(property.substring(0, property.length - 2)) 
                : capitalize(property.substring(0, property.length - 1)) ) +'".'}
          </FormHelperText>
        </FormControl>
      );
    }

    if(data[property] instanceof Date) {
      return (
        <TextField
          style={{ marginTop: 10 }}
          type="datetime-local"
          error={inputErrors.includes(property)}
          variant="outlined" 
          fullWidth 
          onChange={(event) => onChange(property, new Date(event.target.value))}
          helperText={
            'Favor de seleccionar la "' + capitalize(property) + '".'
          }
          value={data[property].toISOString().substring(0, 16)}
        />
      );
    }
  };

  const handleAccept = () => {
    let _inputErrors = [];

    Object.keys(data).forEach((property) => {
      if (
        !Array.isArray(data[property]) &&
        typeof data[property] !== "boolean" &&
        !data[property] && 
        property !== "id" && 
        property !== "eliminado" && 
        property !== "index"
      ) {
        _inputErrors.push(property);
      }
    });

    setInputErrors(_inputErrors);

    if (_inputErrors.length) return;

    onAccept();
  };

  return (
    <>
      <Grid container>
      {
        data && Object.keys(data).map((property, index) => (
          <Grid 
            item 
            md={inColumns ? 4 : 12}
            xs={inColumns ? 4 : 12}
            key={index}
            style={{marginTop: 10}}
          >
            {getInput(property)}
          </Grid>
        ))
      }
      </Grid>
      {
        showButtons && <DialogActions style={{marginTop: 15}}>
          <Button 
            variant="contained"
            onClick={onCancel}
          >
            Cancelar
          </Button>
          {
            saving 
            ?
              <CircularProgress />
            :
              <Button 
                onClick={handleAccept} 
                variant="contained"
                color="secondary"
              >
                { !mode || mode === 'add' ?  'Guardar' : 'Editar'}
              </Button>
          } 
        </DialogActions>
      }
    </>
  );
};

export default Form;