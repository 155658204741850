import asyncComponent from '../components/AsyncComponent';
import HomeView from './HomeView/HomeView';
import LogInView from './LoginView/index';
import NoticePrivacy from './noticePrivacy/NoticePrivacyView';
import IncidentRegistersView from './HomeView/IncidentRegistersView';
const ScheduleView = asyncComponent(() => import('./Schedule'));

export { 
    HomeView, LogInView, NoticePrivacy, IncidentRegistersView, ScheduleView
};
