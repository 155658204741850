import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useUndefinedStyles = makeStyles(theme => ({
  root: {
    color: "#aaaaaa",
    fontStyle: "italic",
  }
}));
  
const Undefined = () => {
  const classes = useUndefinedStyles();
  return (
      <span
      className={classes.root}>
      Sin definir
      </span>
  )
}

export default Undefined;